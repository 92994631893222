Bu sayfadaki puf noktalar: 1 - Bos
<template>
  <transition name="fade">
    <div
      @click="closeAsk"
      style="
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        /* height: max-content; */
        top: 50%;
        z-index: 99999;
        justify-content: center;
        align-items: end;
        background: rgba(56, 49, 49, 0.3);
      "
      v-if="isOpen && showPlatformText"
    >
      <div
        class="card"
        style="height: max-content; margin-bottom: 20%; opacity: 1"
        @click.stop
      >
        <div
          class="innercard d-flex bd-highlight justify-content-between align-items-center"
        >
          <div
            class="p-2 flex-grow-1 bd-highlight rr-cookie-message"
            style="
              flex-direction: column;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="../assets/img/logo-footer.png"
              alt=""
              style="width: 60%; margin-bottom: 20px"
            />
            <div
              style="width: max-content; display: flex; flex-direction: column"
            >
              <span style="color: black; font-weight: 700">
                {{ $t("For A Better Experience") }}
              </span>
              <button
                style="
                  margin-top: 6px;
                  padding: 12px !important;
                  font-size: 1rem !important;
                "
                :style="navbar2Color + '!important'"
                class="btn btn-primary"
                @click="gotoStore"
              >
                {{ $t("Download App") }}
              </button>
            </div>
          </div>
          <div class="p-2 bd-highlight">
            <button
              @click="closeAsk"
              type="button"
              class="btn-text"
              style="margin-right: 6px"
            >
              {{ $t("Close") }}
            </button>
            <button
              v-if="useAskNeverButton2"
              @click="dontAsk"
              type="button"
              class="btn-text"
            >
              {{ $t("Dont Ask") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {
  googleStoreUrl,
  appleStoreUrl,
  useAskNeverButton,
  askPlatformCounter,
  askPlatformMessage,
} from "../zdcconfig/zdconfig.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showPlatformText: false,
      isOpen: false,
      platformUrl: null,
      useAskNeverButton2: useAskNeverButton,
      askPlatformCounter2: askPlatformCounter,
      counter: 0,
    };
  },
  computed: {
    getPlatformCheck() {
      return localStorage.getItem("plch");
    },
    ...mapGetters(["navbar2Color"]),
  },
  methods: {
    getLocalPlatformCounter() {
      const apcValue = localStorage.getItem("apc");
      const defaultValue = apcValue === null ? 0 : apcValue;
      return defaultValue;
    },
    dontAsk() {
      this.handlePlatformCounter(true);
      this.isOpen = false;
      localStorage.setItem("plch", false);
    },
    closeAsk() {
      this.handlePlatformCounter(true);
      this.isOpen = false;
    },
    gotoStore() {
      this.handlePlatformCounter(true);
      this.isOpen = false;
      window.open(this.platformUrl, "_blank");
    },
    handlePlatformCounter(reset) {
      var value = parseInt(this.getLocalPlatformCounter()) + 1;
      if (reset === true) {
        value = 1;
      }
      this.counter = value.toString();
      localStorage.setItem("apc", this.counter);
    },
  },
  created() {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      // iOS
      this.showPlatformText = true;
      this.platformUrl = appleStoreUrl;
    } else if (/Android/.test(navigator.userAgent)) {
      // Android
      this.showPlatformText = true;
      this.platformUrl = googleStoreUrl;
    } else {
      // iOS veya Android dışında bir cihaz kullanılıyor
    }
    if (askPlatformMessage) {
      if (
        !(this.getLocalPlatformCounter() < askPlatformCounter) ||
        this.getLocalPlatformCounter() == 0
      ) {
        if (
          this.getPlatformCheck == null ||
          this.getPlatformCheck === true ||
          !useAskNeverButton
        ) {
          this.isOpen = true;
        } else {
          return;
        }
      }
      if (this.showPlatformText) {
        this.handlePlatformCounter();
      }
    }
  },
};
</script>
<style scoped>
.card {
  border-radius: 6px;
  border: 1px solid #303030;
  width: 40%;
  min-height: 140px;
  background-color: #f1f1f1;
  color: white;
  pointer-events: all;
  padding: 20px 0px;
}
button {
  font-size: 12px;
  margin: 0;
  padding: 10px 20px;
}
.innercard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cookie-gizlilik {
  text-decoration: underline;
  color: white;
}
@media (max-width: 1050px) {
  .card {
    width: 60%;
  }
}
@media (max-width: 850px) {
  .card {
    width: 90%;
    min-height: 100px !important;
  }
}
@media (max-width: 500px) {
  .cookie-message {
    font-size: 12px;
  }
  button {
    font-size: 12px;
    margin: 0;
    padding: 2px 6px;
  }
}
.btn-text {
  display: inline-block;
  font-weight: bold;
  color: rgb(10, 10, 10);
  text-decoration: none;
  background: none;
  border: none;
}
.btn-download {
  background-color: yellow;
  border-radius: 4px;
  font-weight: 600;
  text-decoration: double;
}
</style>
