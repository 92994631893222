<template>
  <div>
    <Navbar />
    <BottomNavMenu />
    <!-- Bu component tum sayfalara eklenecek -->

    <div class="widthfull mt-3">
      <Slide />
    </div>

    <Gruplar />
    <SepetButton />

    <Vitrin />
    <essen-home-extra />
    <!-- <Haftaninfirsatlari @sepeteekle="tumSepet.push($event)" /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import BottomNavMenu from "@/components/BottomNavMenu";
import Gruplar from "@/components/Gruplar";
import SepetButton from "@/components/SepetButton";
import Slide from "@/components/Slide";
import Vitrin from "@/components/Vitrin";
// import Haftaninfirsatlari from "@/components/Haftaninfirsatlari"
import Footer from "@/components/Footer";
import EssenHomeExtra from "../components/EssenHomeExtra.vue";

export default {
  components: {
    Navbar,
    BottomNavMenu,
    Gruplar,
    SepetButton,
    Slide,
    Vitrin,
    EssenHomeExtra,
    // Haftaninfirsatlari,
    Footer,
  },

};
</script>

<style scoped>
[v-cloak] > * {
  display: none;
}
@media (max-width: 3000px) {
  img {
    width: 100vw !important;
  }

  .widthfull {
    width: 100%;
  }
}
@media (max-width: 990px) {
  img {
    width: 95vw !important;
  }

  .widthfull {
    width: 100% !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}
@media (max-width: 768px) {
  .widthfull {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (max-width: 500px) {
  img {
    width: 90vw !important;
  }

  .widthfull {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
</style>
