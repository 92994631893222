import { render, staticRenderFns } from "./ProfilAdreslerim.vue?vue&type=template&id=87fea11e&scoped=true"
import script from "./ProfilAdreslerim.vue?vue&type=script&lang=js"
export * from "./ProfilAdreslerim.vue?vue&type=script&lang=js"
import style0 from "./ProfilAdreslerim.vue?vue&type=style&index=0&id=87fea11e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87fea11e",
  null
  
)

export default component.exports